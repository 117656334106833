<template>
  <!-- <van-list
    v-if="false"
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <div class="h5-category-product">
      <div class="h5-product-list">
        <div
          v-for="(item, index) in recommendList"
          :key="index"
          class="h5-product-item"
          @click="rouGoodDetail(item.id)"
        >
          <div class="h5-product-content">
            <img :src="item.img" alt="" />
            <div class="h5-productname">
              {{ item.name }}
            </div>
            <div class="h5-price">
              ￥{{ item.spec && item.spec.price.toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-list> -->

  <!-- <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  > -->
  <!-- <van-cell v-for="item in list" :key="item" :title="item" />
     -->
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
    class="h5-category-product"
  >
    <div class="h5-product-list">
      <div
        v-for="(item, index) in recommendList"
        :key="index"
        class="h5-product-item"
        @click="rouGoodDetail(item.id)"
      >
        <div class="h5-product-content">
          <img :src="item.img" alt="" />
          <div class="h5-productname">
            {{ item.name }}
          </div>
          <div class="h5-price">
            ￥{{ item.spec && item.spec.price.toFixed(1) }}
          </div>
        </div>
      </div>
    </div>
  </van-list>
  <!-- </van-list> -->
</template>

<script>
import { http } from "../../../request/http";
export default {
  data() {
    return {
      // list: [1, 2, 3, 4, 5, 6, 7, 8, 123, 13, 12, 312, 213],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      recommendList: [],
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        console.log("ss");
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;
        this.finished = false;

        // // 数据全部加载完成
        // if (this.list.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
      return;
      console.log("ss");
      // setTimeout(() => {
      // if (this.refreshing) {
      //   this.list = [];
      //   this.refreshing = false;
      // }
      setTimeout(() => {
        for (let i = 0; i < 20; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;
      }, 1000);

      // if (this.list.length >= 40) {
      //   this.finished = true;
      // }
      // }, 1000);
    },
    // 获取商品列表
    getProductList(name) {
      http("goods.php", {
        fun: "getGoodsEx",
        // is_baseinfo: "0",
        start_num: 0,
        get_num: 30,
      }).then((res) => {
        if (res.code == 0) {
          this.recommendList = res.data;
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.h5-category-product {
  .h5-product-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
    .h5-product-item {
      width: 50%;
      padding: 0 5px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;
      .h5-product-content {
        border: 1px solid #eee;
        padding-bottom: 10px;

        .h5-productname {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #444;
          font-size: 15px;
          margin-top: 10px;
          padding: 0 2px;
        }
        .h5-price {
          color: red;
        }
        img {
          width: 100%;
          height: 163px;
        }
      }
    }
  }
}
</style>